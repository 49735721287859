import React, { useEffect } from "react";
import "./errorPage.css";

const AlreadyResponse = () => {
  function preventBack() {
    window.history.forward(); 
}
  useEffect(() => {
    
  setTimeout(preventBack(), 0);
  }, []);

  return (
    <>
      <section
        className="page_404"
        style={{ display: "grid", placeItems: "center", height: "100vh" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-12 ">
              <div className="col-sm-12 col-sm-offset-1  text-center">
                <div className="txtHeading">
                  {/* <h1 className="text-center ">404</h1> */}
                  <h3 className="text-center">Link has expired</h3>
                </div>

                <div className="four_zero_four_bg"></div>

                {/* <div className="contant_box_404">
      <p>Broken or Incorrect Link. Please try again.</p>
  </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AlreadyResponse;
