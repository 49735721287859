import React from "react";
import { Spinner } from "react-bootstrap";
import "../App.css";
// import { useNavigate } from "react-router-dom";

export default function SpinnerLoad() {
//   let navigate = useNavigate();

//   setTimeout(() => {
//     navigate(`*`);
//   }, 5000);

  return (
    <div className="spinner-loading">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
}
