import React from "react";
import MyVerticallyCenteredModal from "./MyVerticallyCenteredModal";
import Play from '../assets/Icons/Play.png'
import Download from '../assets/Icons/Download.png'

export default function Table(param) {
  const [modalShow, setModalShow] = React.useState(false);
  const [recSrc, setRecSrc] = React.useState("");
  const [recNum, setRecNum] = React.useState("");

  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        recording={recSrc}
        recNumber={recNum}
      />
      <table className="table">
        <thead className="table" style={{color:"#47D1CC"}}>
          <tr>
            <th scope="col">S. No.</th>
            <th scope="col">Recording Name</th>
            <th scope="col">Action</th>
            <th scope="col">Date and Time</th>
          </tr>
        </thead>
        <tbody>
          {(param?.passId?.emergencyRecordings?.length === 0) ? <tr><td colspan="4"><p>Currently there are no recordings available Kindly, check back later.</p></td></tr> : 
          param?.passId?.emergencyRecordings?.map((data, i) => {
            return (
              <tr>
                <th scope="row">{i + 1}</th>
                <td>Audio {i + 1}</td>
                <td>
                  <button class="btn btn-default" onClick={() => {setModalShow(true); setRecSrc(data); setRecNum(i+1)}}>
                    <img src={Play} width="30" alt="#"/>
                  </button>{" "}
                  <a href={data.recordingFile} class="btn btn-default">
                  <img src={Download} width="30" alt="#"/>
                  </a>
                </td>
                <td>{new Date(data?.createdAt).toLocaleString([], { hour12: true})}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
