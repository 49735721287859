import React, { useEffect } from "react";
import './thankyou.css';

const ThankYou = () => {

  function preventBack() {
    window.history.forward(); 
}
  useEffect(() => {
    
  setTimeout(preventBack(), 0);
  }, []);

  return (
    <div id="main">
      <div class="fof">
        <h1>Thank You</h1>
      </div>
    </div>
  );
};

export default ThankYou;
