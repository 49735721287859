import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "./Config/Config";


const Consent = () => {
  let navigate = useNavigate();

  const queryParams = new URLSearchParams(window.location.search);
  const consent = queryParams.get("consent");

  const axios = require("axios").default;

  const [isUiRender, setIsUiRender] = useState(false)

  const [userName, setUserName] = useState(null);
  const getConsentDataDetails = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/v1/emergency-contact/get-consent-link-data/${consent}`
      );
      if (response?.status === 200) {
        console.log("response", response?.data?.data);
        if (response?.data?.data?.isExpired) {
          //redirect to experice page
          navigate(`/link-expired`);
        } else {
          setUserName(response?.data?.data?.user);
          setIsUiRender(true)
        }
      }
    } catch (error) {
      console.log("here error", error);
    }
  };

  useEffect(() => {
    getConsentDataDetails();
  }, []);

  const changeValue = async (e) => {
      const requestParameter = {
        "status": Number(e.target.value)
      }
      try {
        const response = await axios.patch(
          `${API_URL}/v1/emergency-contact/update-consent-status/${consent}`,
          requestParameter
        );
        if (response?.status === 200) {
          console.log("response", response?.data?.message);
          alert(response?.data?.message)
          navigate(`/thank-you`);
        }
      } catch (error) {
        console.log("here error", error);
      }
  }

  return (
    <div style={{ margin: "2rem" }}>
     {isUiRender &&  <><h2>
        Would you like to continue receiving emergency text alerts from{" "}
        {userName}
      </h2>
      <div onChange={changeValue}>
        <input type="radio" value="2" name="consent" /> Yes
        <input style={{marginLeft: "2rem"}} type="radio" value="3" name="consent" /> No
      </div>
      </>}
    </div>
  );
};

export default Consent;
