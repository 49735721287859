import React from "react";
import MianPage from "./MianPage";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import ErrorPage from "./ErrorPage";
import Consent from "./Consent";
import ThankYou from "./ThankYou";
import AlreadyResponse from "./AlreadyResponse";

function App() {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  console.log("id", id)
  return (
    <>
    <Router>
      <Routes>
        {id !== null ? <Route exact path="/" element={<MianPage />} /> : <Route exact path="/" element={<Consent/>} /> }
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/link-expired" element={<AlreadyResponse />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
    </>
  );
}

export default App;
